import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { VitalsColorKeys, vitalColors } from '../../theme/colors';
import TimedValueComponent from '../atoms/TimedValue';

interface ValueDisplayProps {
  small?: boolean;
  value: number;
  unit: string;
  displayColor?: VitalsColorKeys;
  gt?: boolean;
  gt_value?: number
  gtValid?: boolean
  valid?: boolean
  precision?: number
}

interface StyledSpanProps {
  displaycolor: VitalsColorKeys | string;
  small: boolean;
}

const ValueText = styled.span<StyledSpanProps>`
  font-size: ${props => (props.small ? '1.5' : '2')}em;
  color: ${props => props.displaycolor};
  @media (min-width: 576px) {
    font-size: ${props => (props.small ? '1.5' : '2.5')}em;
  }
  @media (min-width: 768px) {
    font-size: ${props => (props.small ? '2.25' : '3')}em;
  }
  @media (min-width: 992px) {
    font-size: ${props => (props.small ? '2.75' : '3.5')}em;
  }
  @media (min-width: 1200px) {
    font-size: ${props => (props.small ? '3' : '4')}em;
  }
  @media (min-width: 1400px) {
    font-size: ${props => (props.small ? '3.75' : '4.5')}em;
  }
`;

const VitalsBlock = styled(Card) <{ displaycolor: string }>`
  border: 1px solid ${props => props.displaycolor};
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Adjust as needed */
`;

const ValueDisplay: React.FC<ValueDisplayProps> = ({ value, unit, displayColor = 'white', small = false, gt = false, gt_value, valid, gtValid = false, precision }) => {

  return (
    <VitalsBlock displaycolor={vitalColors[displayColor]}>
      <Card.Body>
        <Card.Text>
          <TimedValueComponent displayColor={displayColor} small={small || gt} value={value} valid={valid || false} unit={unit} precision={precision}/>
          {gt && <>
            <ValueText displaycolor={vitalColors[displayColor]} small={true}>
              &nbsp;|&nbsp;
            </ValueText>
            <TimedValueComponent displayColor={displayColor} small={true} value={gt_value || -1} valid={gtValid} unit={unit} precision={precision}/>
          </>}
        </Card.Text>
      </Card.Body>
    </VitalsBlock>
  );
};

export default ValueDisplay;
